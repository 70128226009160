<template>
  <div class="base-interaction bucketlist-interaction">
    <div
      ref="download_container"
      class="base-interaction__container base-interaction__container--download"
    >
      <inline-svg
        ref="download_svg"
        :src="require('@/assets/svg/bucketlist_download.svg')"
      />
    </div>

    <div class="base-interaction__container" v-if="success">
      <h2>
        Well done! We will get back to you in the future to remind you of your
        goals.
      </h2>
      <h3>In the meantime, feel free to further explore UCHRONIA.</h3>

      <Button class="button--small" @click.native="onExitClick"
        >BACK TO JOURNEY</Button
      >
    </div>

    <div
      class="base-interaction__container base-interaction__container--lottie"
      ref="globe_animation"
      v-else-if="!success && !downloaded && !intro_done && activeAnimation"
    >
      <h2>Around the World</h2>
      <h3>
        We asked our impress colleagues from around the world what is on
        their<br />bucket list. For sure they will inspire you as well - have a
        look below!
      </h3>
      <div class="wish__container">
        <lottie-animation
          ref="lottie"
          :path="'assets/lottie/' + activeAnimation.lottie + '.json'"
          :loop="false"
          :autoPlay="true"
          :width="320"
          :height="320"
          @AnimControl="setAnimController"
        />

        <div class="wish__table" ref="wish_table">
          <h2>{{ activeAnimation.head }}</h2>
          <p>{{ activeAnimation.text }}</p>
        </div>
      </div>

      <div class="base-interaction__container-buttons">
        <Button class="button--small" @click.native="onNextWish"
          >SHOW NEXT WISH</Button
        >
        <Button class="button--small" @click.native="onSkipIntro"
          >CREATE YOUR OWN</Button
        >
      </div>
    </div>

    <div class="base-interaction__container" v-else-if="intro_done">
      <h2>What about you? What are your dreams and wishes for the future?</h2>
      <h3>
        Type in your top 3 things, you always wanted to experience or achieve in
        your life.
      </h3>

      <div class="bucketlist-interaction__inputs">
        <div class="bucketlist-interaction__input">
          <input maxlength="32" ref="input1" />
          <span class="bucketlist-interaction__input-digit">1</span>
        </div>

        <div class="bucketlist-interaction__input">
          <input maxlength="32" ref="input2" />
          <span class="bucketlist-interaction__input-digit">2</span>
        </div>

        <div class="bucketlist-interaction__input">
          <input maxlength="32" ref="input3" />
          <span class="bucketlist-interaction__input-digit">3</span>
        </div>
      </div>

      <div class="base-interaction__container-buttons">
        <Button class="button--small" @click.native="onDownloadClick"
          >DOWNLOAD</Button
        >
        <Button class="button--small" @click.native="onSkipClick">EXIT</Button>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import axios from "axios";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";
import Button from "../../form/Button.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  name: "BucketlistInteraction",
  mixins: [DownloadHelperMixin],
  components: { Button, LottieAnimation },
  data() {
    return {
      globeAnimations: [
        {
          lottie: "Austria-WORLD",
          head: "Wish by Florian from Austria",
          text:
            "I want to go with my 12 year old Vespa from Vienna to Trieste via the old historical road. "
        },
        {
          lottie: "Brasil-WORLD",
          head: "Wish by Wellington from Brasil",
          text: "I want to row in the icy waters of Norway. "
        },
        {
          lottie: "California-WORLD",
          head: "Wish by Bruce from California",
          text: "I want to hike all 2,000 miles of the Appalachian Trail. "
        },
        {
          lottie: "Canada-WORLD",
          head: "Wish by Silvia from Canada",
          text: "I want to travel all of Asia."
        },
        {
          lottie: "China-WORLD",
          head: "Wish by Sherry from China",
          text: "I want to take a 'La La Land' tour in Los Angeles. "
        },
        {
          lottie: "Colombia-WORLD",
          head: "Wish by Laura from Colombia",
          text: "I want to travel to space."
        },
        {
          lottie: "Cypern-WORLD",
          head: "Wish by Anastasiya from Cyprus",
          text: "I want to skydive in Dubai above Palm Jumeirah. "
        },
        {
          lottie: "Germany-WORLD",
          head: "Wish by Janina from Germany",
          text:
            "I want to cross the alps off road with the enduro and enjoy nature."
        },
        {
          lottie: "Pensylvania-WORLD",
          head: "Wish by Mariah from Pennsylvania",
          text: "I want to visit every continent (especially Antarctica!) "
        },
        {
          lottie: "Poland-WORLD",
          head: "Wish by Rafał from Poland",
          text:
            "I want to build a pizza oven out of vermiculite and master pizza baking."
        },
        {
          lottie: "Russia-WORLD",
          head: "Wish by Galina from Russia",
          text: "I want to visit Rome and explore all the Roman ruins. "
        },
        {
          lottie: "SouthAfrika-WORLD",
          head: "Wish by Troy from South Africa",
          text: "I want to get my kicks on Route 66 in a classic muscle car."
        },
        {
          lottie: "Spain-WORLD",
          head: "Wish by Eduard from Spain",
          text: "I want to spend 2 years traveling around the world."
        }
      ],
      activeAnimation: undefined,
      animation: undefined,
      animationSkipped: false,
      intro_done: false,
      downloaded: false,
      monthsSet: false,
      success: false,
      wishes: {},
      wishIndex: undefined
    };
  },
  methods: {
    killLottieAnimation() {
      if (this.animation) {
        //this.animation.removeEventListener("complete", this.testEvent);
        this.animation.removeEventListener("enterFrame", this.onEnterFrame);
        this.animation.destroy();
        this.animation = null;
      }
    },
    onEnterFrame() {
      const percent = this.animation.currentFrame / this.animation.totalFrames;
      if (percent >= 0.6 && !this.animationSkipped) this.animation.pause();

      if (percent >= 0.99 && this.animationSkipped) {
        this.intro_done = true;
        this.killLottieAnimation();
      }
    },
    testEvent() {
      //gsap.delayedCall(0.5, this.onSkipIntro);
    },
    onNextWish() {
      this.killLottieAnimation();

      const lastWishIndex = this.wishIndex;

      let newIndex = (newIndex = Math.round(
        Math.random() * (this.globeAnimations.length - 1)
      ));

      while (newIndex === lastWishIndex) {
        newIndex = Math.round(
          Math.random() * (this.globeAnimations.length - 1)
        );
      }

      this.wishIndex = newIndex;

      this.activeAnimation = this.globeAnimations[this.wishIndex];

      this.showWish();
    },

    showWish() {
      gsap.set(this.$refs.wish_table, { x: 0, autoAlpha: 0, overwrite: true });
      gsap.set(this.$refs.wish_table, {
        x: 0,
        autoAlpha: 0,
        scaleX: 0.8,
        scaleY: 0.8,
        xPercent: -50,
        yPercent: -50,
        overwrite: true
      });

      gsap.to(this.$refs.wish_table, {
        delay: 0.6,
        duration: 0.7,
        autoAlpha: 1
      });
      gsap.to(this.$refs.wish_table, {
        delay: 0.6,
        duration: 1,
        x: 0,
        scaleX: 1,
        scaleY: 1,
        ease: "Expo.easeOut"
      });
    },
    onSkipIntro() {
      //this.animationSkipped = true;
      //this.animation.play();
      this.intro_done = true;
      this.killLottieAnimation();
    },
    setAnimController(ani) {
      console.log("NEW ANIMCONTROLLER");
      this.animation = ani;
      //ani.addEventListener("complete", this.testEvent);
      ani.addEventListener("enterFrame", this.onEnterFrame);
      ani.play();
    },
    checkMonths() {
      if (this.$refs.months_input.value >= 1) {
        this.monthsSet = true;
      } else {
        this.monthsSet = false;
      }
    },
    onSuccess() {
      this.success = true;
    },
    onSkipClick() {
      this.$emit("exit");
    },
    onSetReminderClick() {
      const months = this.$refs.months_input.value;
      let user = this.$store.getters.getUser;

      var d = new Date();
      const moment = this.$moment(d).add(months, "M");
      const unix = moment.unix();

      if (!user) {
        user = {
          email: "email@email.com",
          display_name: "Name",
          city: "city",
          country: "country"
        };
      }

      axios
        .post("//cms.digitalimpress.biz/wp-json/bucketlistapi/v1/bucketlist", {
          data: {
            email: user.email,
            name: user.display_name,
            city: user.city,
            country: user.country,
            remind_at: unix,
            remind_clean: moment.format("YYYY-MM-DD"),
            wish1: this.wishes.wish1,
            wish2: this.wishes.wish2,
            wish3: this.wishes.wish3
          }
        })
        .then(() => this.onSuccess())
        .catch(err => console.log(err));
    },
    download() {
      //this.downloaded = true;
      //this.createPngFromElement(this.$refs.download_container,'lalalilo');

      this.$el.getElementsByClassName(
        "js-wish1"
      )[0].innerHTML = this.wishes.wish1;
      this.$el.getElementsByClassName(
        "js-wish2"
      )[0].innerHTML = this.wishes.wish2;
      this.$el.getElementsByClassName(
        "js-wish3"
      )[0].innerHTML = this.wishes.wish3;

      this.createPngFromSvg(
        this.$refs.download_svg.$el,
        "bucketlist.png",
        true,
        1440,
        966
      );

      this.downloaded = true;
    },
    onDownloadClick() {
      this.wishes = {
        wish1: this.$refs.input1.value,
        wish2: this.$refs.input2.value,
        wish3: this.$refs.input3.value
      };
      this.download();
    },

    onExitClick() {
      this.$emit("exit");
    },
    onResize() {},
    init() {
      this.onResize();
    }
  },
  watch: {
    intro_done() {
      this.$nextTick(() => {
        gsap.from(this.$el, {
          ease: "Expo.easeOut",
          duration: 1,
          alpha: 0
        });
      });
    },
    downloaded() {
      this.$nextTick(() => {
        gsap.from(this.$el, {
          ease: "Expo.easeOut",
          duration: 1,
          alpha: 0
        });
      });
    },
    stageWidth() {
      this.onResize();
    }
  },
  computed: {
    stageWidth() {
      return this.$screen.width;
    }
  },
  beforeDestroy() {
    this.killLottieAnimation();
  },

  mounted() {
    this.wishIndex = Math.round(
      Math.random() * (this.globeAnimations.length - 1)
    );
    this.activeAnimation = this.globeAnimations[this.wishIndex];

    gsap.from(this.$el, {
      ease: "Expo.easeOut",
      duration: 2,
      scale: 1.2,
      alpha: 0
    });

    this.$nextTick(() => {
      this.showWish();
    });
  }
};
</script>

<style scoped lang="scss"></style>
